import { encodeStringHighlightToUrl } from 'src/utils/url.utils';
import { ContactExtractionStatus } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { getFullName } from 'src/domain/models/user/user.model';
import {
    type DealCompanyCandidate,
    type DealContactCandidate,
    type ContactExtractionResultData,
    ContactExtractionResultStatus,
} from '../../data/contact-extraction.model';

export const normalizeCandidateNameForComparison = (name: string): string => {
    return name
        .toLowerCase()
        .replace(/[^a-z0-9]/g, '')
        .trim();
};

export const checkIfHasExtractedData = (
    result: ContactExtractionResultData | undefined,
): boolean => {
    if (!result) return false;
    return (
        result.extractedContacts.length > 0 ||
        result.extractedCompanies.length > 0
    );
};

export const determineStatusWithoutWebExtractionFeature = (
    projectContextResult: ContactExtractionResultData | null | undefined,
): ContactExtractionResultStatus => {
    if (!projectContextResult) return ContactExtractionResultStatus.NeverRun;

    if (projectContextResult.status === ContactExtractionStatus.Failed) {
        return ContactExtractionResultStatus.Error;
    }

    if (projectContextResult.status === ContactExtractionStatus.InProgress) {
        return ContactExtractionResultStatus.InProgress;
    }

    if (projectContextResult.status === ContactExtractionStatus.Success) {
        return checkIfHasExtractedData(projectContextResult)
            ? ContactExtractionResultStatus.Success
            : ContactExtractionResultStatus.NoContacts;
    }

    return ContactExtractionResultStatus.NeverRun;
};

export const determineStatusWithWebExtractionFeature = (
    projectContextResult: ContactExtractionResultData | null | undefined,
    webSearchResult: ContactExtractionResultData | null | undefined,
): ContactExtractionResultStatus => {
    if (!projectContextResult || !webSearchResult)
        return ContactExtractionResultStatus.NeverRun;

    if (
        projectContextResult.status === ContactExtractionStatus.Failed &&
        webSearchResult.status === ContactExtractionStatus.Failed
    ) {
        return ContactExtractionResultStatus.Error;
    }

    if (projectContextResult.status === ContactExtractionStatus.InProgress) {
        return ContactExtractionResultStatus.ArticlesSearchInProgress;
    }

    if (webSearchResult.status === ContactExtractionStatus.InProgress) {
        return ContactExtractionResultStatus.WebSearchInProgress;
    }

    if (
        projectContextResult.status === ContactExtractionStatus.Success ||
        webSearchResult.status === ContactExtractionStatus.Success
    ) {
        return checkIfHasExtractedData(projectContextResult)
            ? ContactExtractionResultStatus.Success
            : ContactExtractionResultStatus.NoContacts;
    }

    return ContactExtractionResultStatus.NeverRun;
};

export const getCombinedCandidates = (
    projectContextResult: ContactExtractionResultData | null | undefined,
    webSearchResult?: ContactExtractionResultData | null | undefined,
) => {
    const articleSearchContacts = projectContextResult?.extractedContacts ?? [];
    const articleSearchCompanies =
        projectContextResult?.extractedCompanies ?? [];
    const webSearchContacts = webSearchResult?.extractedContacts ?? [];
    const webSearchCompanies = webSearchResult?.extractedCompanies ?? [];

    const contactMap = [...articleSearchContacts, ...webSearchContacts].reduce(
        (result, contact) => {
            const contactName = getFullName(
                contact.firstName,
                contact.lastName,
            );
            const normalizedName =
                normalizeCandidateNameForComparison(contactName);

            // Deduplicate contacts by normalized name.
            const isContactAlreadyInMap = result.has(normalizedName);
            if (isContactAlreadyInMap) return result;

            result.set(normalizedName, {
                ...contact,
                // Highlight contact name in source URLs.
                sourceUrls: contact.sourceUrls.map((url) =>
                    encodeStringHighlightToUrl(url, contactName),
                ),
            });

            return result;
        },
        new Map<string, DealContactCandidate>(),
    );

    const companyMap = [
        ...articleSearchCompanies,
        ...webSearchCompanies,
    ].reduce((result, company) => {
        const companyName = company.name;
        const normalizedName = normalizeCandidateNameForComparison(companyName);

        // Deduplicate companies by normalized name.
        const isCompanyAlreadyInMap = result.has(normalizedName);
        if (isCompanyAlreadyInMap) return result;

        result.set(normalizedName, {
            ...company,
            // Highlight company name in source URLs.
            sourceUrls: company.sourceUrls.map((url) =>
                encodeStringHighlightToUrl(url, companyName),
            ),
        });

        return result;
    }, new Map<string, DealCompanyCandidate>());

    return {
        contacts: Array.from(contactMap.values()),
        companies: Array.from(companyMap.values()),
    };
};
