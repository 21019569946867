import { makeAutoObservable } from 'mobx';
import { getI18n, TFunction } from 'react-i18next';

import { AuthApi } from 'src/data/api/auth/auth.api';
import { UserAuthenticationStatus } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { UserAuthResponse, UsersApi } from 'src/data/api/user/user.api';
import { FeatureFlagsService } from 'src/data/services/feature-flags/feature-flags.service';
import { HubSpotService } from 'src/data/services/hubspot/hubspot.service';
import { LocalStorageService } from 'src/data/services/local-storage/local-storage.service';
import { LocationService } from 'src/data/services/location/location.service';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { PendoService } from 'src/data/services/pendo/pendo.service';
import {
    ErrorsStore,
    UNAUTHORIZED_MESSAGE,
} from 'src/data/stores/errors/errors.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { UserStore } from 'src/data/stores/user/user.store';
import { AssociatedSearchesFeature } from 'src/domain/features/associated-searches/associated-searches.feature';
import { NotificationsFeature } from 'src/domain/features/notifications/notifications-interface.feature';
import { TranslationFeature } from 'src/domain/features/translation/translation.feature';
import { Colleague } from 'src/domain/models/colleague/colleague.model';
import { User } from 'src/domain/models/user/user.model';
import { WebAppRoute } from 'src/presentation/modules/router/app-route.list';
import { NewPasswordForm } from 'src/presentation/modules/settings/account-settings/new-password-modal/new-password-modal.util';
import { doNothing } from 'src/utils/function.utils';
import { Cancellable, handleRequest } from 'src/utils/handle-request.utils';
import { reload } from 'src/utils/url.utils';

import {
    buildLoginErrorMessage,
    buildCreateNewPasswordErrorMessage,
    sessionCookieExists,
    buildInactiveErrorMessage,
} from './auth.feature.util';

export interface AuthFeature {
    previousPathname: string | null;
    hasUnauthorizedError: boolean;
    hasLoginError: boolean;
    hasNewPasswordError: boolean;
    isLoading: boolean;
    isAuthorized: boolean;
    user: User | undefined;
    /**
     * Holds a list of active users. It's ordered alphabetically and the current
     * logged user is always the first, regardless their name
     */
    assignableUsers: Colleague[];
    login: (email: string, password: string) => void;
    resetPassword: (email: string) => void;
    setNewPassword: (password: string, token: string) => void;
    logout: () => void;
    loginWithExistingUser: () => void;
    setPreviousPathname: (pathname: string | null) => void;
    changePassword: (newPasswordForm: NewPasswordForm) => Cancellable;
    impersonateUser: (email: string) => void;
    endImpersonation: () => void;
}

const LOGIN_ERROR_KEY = 'login';
const NEW_PASSWORD_ERROR_KEY = 'NEW_PASSWORD_ERROR_KEY';

export class AuthFeatureImpl implements AuthFeature {
    t: TFunction<'translation', undefined> = getI18n().t;
    isLoading = false;
    hasXsrf = false;
    previousPathname: string | null = null;

    get isAuthorized() {
        return this.userStore.user !== undefined && !this.hasUnauthorizedError;
    }

    get user(): User | undefined {
        return this.userStore.user;
    }

    get hasUnauthorizedError(): boolean {
        return !!this.errorStore.unauthorizeError;
    }

    get hasResetPasswordError(): boolean {
        return !!this.errorStore.unauthorizeError;
    }

    get hasLoginError(): boolean {
        return this.errorStore.errors.has(LOGIN_ERROR_KEY);
    }

    get hasNewPasswordError(): boolean {
        return this.errorStore.errors.has(NEW_PASSWORD_ERROR_KEY);
    }

    setLoading = (isLoading: boolean) => {
        this.isLoading = isLoading;
    };

    setUserOnLogin = (response: UserAuthResponse) => {
        if (response.status === UserAuthenticationStatus.Success) {
            const { user, hubspotToken } = response;
            this.locationService.redirectToOldOrNewAppIfNeeded(user);
            this.userStore.setUser(user);
            if (!user.isImpersonated) {
                this.pendoService.init(user);
                if (hubspotToken) {
                    this.hubspotService.logInHubSpot(user.email, hubspotToken);
                }
            }
            this.localStorageService.setUserId(user.itemId);
            this.mixpanelService.init(
                user,
                user.companySettings.accountType,
                user.companySettings.licenseType,
            );
            this.translationFeature.setLanguage(user.language);
            this.featureFlagsService.logIn(user);
            this.notificationsFeature.requestReminders();
            this.associatedSearchesFeature.requestSearchSubscriptions();
        } else if (response.status === UserAuthenticationStatus.UserInactive) {
            this.toasterStore.showMessage(buildInactiveErrorMessage());
        } else {
            this.toasterStore.showMessage(buildLoginErrorMessage());
        }
    };

    setLoginError = (error?: Error) => {
        this.baseStore.onRequestFailed(LOGIN_ERROR_KEY, error, {
            defaultErrorToasterEnabled: false,
        });

        if (error) {
            this.toasterStore.showMessage(buildLoginErrorMessage());
        }

        this.hubspotService.logOutHubSpot();
        this.featureFlagsService.logOut();
    };

    setNewPasswordError = (error?: Error) => {
        this.baseStore.onRequestFailed(NEW_PASSWORD_ERROR_KEY, error, {
            defaultErrorToasterEnabled: false,
        });

        if (error) {
            this.toasterStore.showMessage(buildCreateNewPasswordErrorMessage());
        }
    };

    setPreviousPathname = (pathname: string | null) => {
        this.previousPathname = pathname;
    };

    constructor(
        private authApi: AuthApi,
        private errorStore: ErrorsStore,
        private userStore: UserStore,
        private localStorageService: LocalStorageService,
        private usersApi: UsersApi,
        private pendoService: PendoService,
        private toasterStore: ToasterStore,
        private locationService: LocationService,
        private hubspotService: HubSpotService,
        private pipelineStore: PipelineStore,
        private mixpanelService: MixpanelService,
        private translationFeature: TranslationFeature,
        private featureFlagsService: FeatureFlagsService,
        private notificationsFeature: NotificationsFeature,
        private baseStore: IBaseStore,
        private associatedSearchesFeature: AssociatedSearchesFeature,
    ) {
        makeAutoObservable(this);
    }

    login = (email: string, password: string) => {
        this.errorStore.clearAllErrors();
        this.pipelineStore.needToRequestPipelines = true;
        handleRequest(
            this.authApi.signIn,
            { email, password },
            this.setUserOnLogin,
            this.setLoading,
            this.setLoginError,
        );
    };

    setNewPassword = async (password: string, token: string) => {
        this.errorStore.clearAllErrors();
        handleRequest(
            this.authApi.setNewPassword,
            { password, token },
            () => doNothing,
            this.setLoading,
            this.setNewPasswordError,
        );
    };

    logout = () => {
        handleRequest(
            this.authApi.logout,
            {},
            this.onLogoutResponse,
            doNothing,
            (error) => this.baseStore.onRequestFailed('logout', error),
        );
    };

    onLogoutResponse = () => {
        this.userStore.setUser(undefined);
        this.userStore.setUserAchievements(undefined);
        this.errorStore.clearAllErrors();
        this.hubspotService.logOutHubSpot();
        this.mixpanelService.stopSessionRecording();
        this.featureFlagsService.logOut();
    };

    resetPassword = (email: string) => {
        handleRequest(
            this.authApi.resetPassword,
            { email },
            () => doNothing,
            this.setLoading,
            (error) => this.baseStore.onRequestFailed('reset-password', error),
        );
    };

    redirectToLogin = () => {
        this.errorStore.handle('user')(new Error(UNAUTHORIZED_MESSAGE));
        this.hubspotService.logOutHubSpot();
        this.featureFlagsService.logOut();
    };

    onLoginWithExistingUserError = (error?: Error) => {
        if (error) {
            this.baseStore.onRequestFailed('login-with-existing-user', error, {
                defaultErrorToasterEnabled: false,
                enableSentryErrorCapture: false,
            });
            this.redirectToLogin();
        }
    };

    loginWithExistingUser = () => {
        if (!sessionCookieExists()) {
            return this.redirectToLogin();
        }
        handleRequest(
            this.usersApi.getCurrentUser,
            {},
            this.setUserOnLogin,
            this.setLoading,
            this.onLoginWithExistingUserError,
        );
    };

    onChangePassword = () => {
        this.toasterStore.showMessage({
            title: this.t('auth.password_updated_success_message'),
            type: 'success',
        });
    };

    changePassword = ({
        currentPassword,
        newPassword,
        reNewPassword,
    }: NewPasswordForm) => {
        return handleRequest(
            this.usersApi.changePassword,
            {
                password: currentPassword,
                new_password: newPassword,
                repeat_new_password: reNewPassword,
            },
            this.onChangePassword,
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('change-password', error, {
                    errorMessage: this.t(
                        'auth.password_updated_failure_message',
                    ),
                }),
        );
    };

    get assignableUsers(): Colleague[] {
        return this.userStore.assignableUsers;
    }

    impersonateUser = (email: string) => {
        return handleRequest(
            this.usersApi.impersonateUser,
            {
                email,
            },
            reload,
            (flag) => flag && this.setLoading(flag),
            (error) => {
                if (error) {
                    this.logout();
                }
                this.baseStore.onRequestFailed('impersonate-user', error);
            },
        );
    };

    endImpersonation = () => {
        handleRequest(
            this.authApi.logout,
            {},
            () => {
                window.location.href = WebAppRoute.adminAppCompanyInfo.replace(
                    '{companyId}',
                    this.user?.companyId.toString() ?? '',
                );
            },
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'logout-end-impersonation',
                    error,
                ),
        );
    };
}
