import { makeAutoObservable } from 'mobx';
import { FeatureFlag } from 'src/data/services/feature-flags/feature-flags.model';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import { Language } from 'src/domain/models/locale/locale.model';
import { getFullName } from 'src/domain/models/user/user.model';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { getFeatureFlagBool } from 'src/utils/hooks/use-feature-flag';
import { type IDealContactsFeature } from 'src/app-features/contact/domain/interfaces/deal-contacts.feature.interface';
import {
    ContactExtractionResultStatus,
    DealCompanyCandidate,
    DealContactCandidate,
} from '../../data/contact-extraction.model';
import { IContactExtractionStore } from '../../data/store/contact-extraction.interface.store';
import { doNothing } from 'src/utils/function.utils';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import {
    ActionFeatureIdentifierNamesMap,
    ActionFeaturesIdentifiers,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { type IContactExtractionFeature } from './contact-extraction-interface.feature';
import { type IContactExtractionApi } from '../../data/contact-extraction.api';
import {
    determineStatusWithoutWebExtractionFeature,
    determineStatusWithWebExtractionFeature,
    getCombinedCandidates,
} from './contact-extraction.helpers';

export class ContactExtractionFeature implements IContactExtractionFeature {
    constructor(
        private contactExtractionApi: IContactExtractionApi,
        private contactExtractionStore: IContactExtractionStore,
        private baseStore: IBaseStore,
        private userStore: UserStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private mixpanelService: MixpanelService,
        private dealContactsFeature: IDealContactsFeature,
    ) {
        makeAutoObservable(this);
    }

    isFlyoutOpen: IContactExtractionFeature['isFlyoutOpen'] = false;

    get isFeatureEnabled(): IContactExtractionFeature['isFeatureEnabled'] {
        return this.accountConfigurationStore.isActionFeatureEnabled(
            ActionFeaturesIdentifiers.contact_extractor,
        );
    }

    get isContactWebExtractionFeatureEnabled(): IContactExtractionFeature['isContactWebExtractionFeatureEnabled'] {
        return getFeatureFlagBool(FeatureFlag.contactWebExtraction);
    }

    get contactExtractionResultStatus(): IContactExtractionFeature['contactExtractionResultStatus'] {
        return this.contactExtractionStore.contactExtractionResultStatus;
    }

    get contactExtractionCandidates(): IContactExtractionFeature['contactExtractionCandidates'] {
        return this.contactExtractionStore.contactExtractionCandidates;
    }

    get companyExtractionCandidates(): IContactExtractionFeature['companyExtractionCandidates'] {
        return this.contactExtractionStore.companyExtractionCandidates;
    }

    get selectedContactCandidateNames(): IContactExtractionFeature['selectedContactCandidateNames'] {
        return this.contactExtractionStore.selectedContactCandidateNames;
    }

    get selectedCompanyCandidateNames(): IContactExtractionFeature['selectedCompanyCandidateNames'] {
        return this.contactExtractionStore.selectedCompanyCandidateNames;
    }

    getSelectedContactCandidates: IContactExtractionFeature['getSelectedContactCandidates'] =
        () => {
            return this.contactExtractionCandidates.filter((contact) =>
                this.selectedContactCandidateNames.has(
                    getFullName(contact.firstName, contact.lastName),
                ),
            );
        };

    getSelectedCompanyCandidates: IContactExtractionFeature['getSelectedCompanyCandidates'] =
        () => {
            return this.companyExtractionCandidates.filter((company) =>
                this.selectedCompanyCandidateNames.has(company.name),
            );
        };

    onSelectContactCandidate: IContactExtractionFeature['onSelectContactCandidate'] =
        (candidate, selected) => {
            const contactCompany = this.companyExtractionCandidates.find(
                (company) => company.name === candidate.companyName,
            );

            if (selected) {
                this.contactExtractionStore.selectContact(candidate);

                if (contactCompany) {
                    this.contactExtractionStore.selectCompany(contactCompany);
                }
            } else {
                this.contactExtractionStore.deselectContact(candidate);

                if (contactCompany) {
                    this.contactExtractionStore.deselectCompany(contactCompany);
                }
            }
        };

    onSelectCompanyCandidate: IContactExtractionFeature['onSelectCompanyCandidate'] =
        (candidate, selected) => {
            if (selected) {
                this.contactExtractionStore.selectCompany(candidate);
            } else {
                this.contactExtractionStore.deselectCompany(candidate);
            }
        };

    getAutoExtractionResult: IContactExtractionFeature['getAutoExtractionResult'] =
        async (dealId) => {
            const response = await handleRequestAsync(
                this.contactExtractionApi.getAutoExtractionResult,
                { dealId },
                doNothing,
                (error) => {
                    if (!error) return;

                    this.baseStore.onRequestFailed(
                        'request-auto-extract-contacts',
                        error,
                    );

                    this.setContactExtractionResultStatus(
                        ContactExtractionResultStatus.Error,
                    );
                },
                'getAutoExtractionResult',
            );

            this.setContactExtractionResult(response);
        };

    rerunAutoExtraction: IContactExtractionFeature['rerunAutoExtraction'] =
        async (dealId) => {
            this.setContactExtractionResultStatus(
                this.isContactWebExtractionFeatureEnabled
                    ? ContactExtractionResultStatus.ArticlesSearchInProgress
                    : ContactExtractionResultStatus.InProgress,
            );

            await handleRequestAsync(
                this.contactExtractionApi.runAutoExtraction,
                {
                    dealId,
                    language: this.userStore.user?.language ?? Language.De,
                },
            );

            await this.getAutoExtractionResult(dealId);
        };

    setContactExtractionCandidates: IContactExtractionFeature['setContactExtractionCandidates'] =
        (candidates) => {
            this.contactExtractionStore.setContactExtractionCandidates(
                candidates,
            );
        };

    setCompanyExtractionCandidates: IContactExtractionFeature['setCompanyExtractionCandidates'] =
        (candidates) => {
            this.contactExtractionStore.setCompanyExtractionCandidates(
                candidates,
            );
        };

    openFlyout: IContactExtractionFeature['openFlyout'] = (
        dealId,
        launchSource,
    ) => {
        this.isFlyoutOpen = true;
        this.mixpanelService.trackEvent(
            MixpanelEventName.LaunchedQuickAction,
            {
                quickActionName:
                    ActionFeatureIdentifierNamesMap[
                        ActionFeaturesIdentifiers.contact_extractor
                    ],
                launchSource: launchSource.toString(),
            },
            dealId,
        );
    };

    closeFlyout: IContactExtractionFeature['closeFlyout'] = () => {
        this.isFlyoutOpen = false;
    };

    setContactExtractionResultStatus: IContactExtractionFeature['setContactExtractionResultStatus'] =
        (status) => {
            this.contactExtractionStore.setContactExtractionResultStatus(
                status,
            );
        };

    setContactExtractionResult: IContactExtractionFeature['setContactExtractionResult'] =
        (result) => {
            if (!result) {
                this.clear();
                return;
            }

            const { projectContextResult, webSearchResult } = result;
            const isContactWebExtractionEnabled =
                this.isContactWebExtractionFeatureEnabled;

            let status: ContactExtractionResultStatus;
            let candidates: {
                contacts: DealContactCandidate[];
                companies: DealCompanyCandidate[];
            };

            if (!isContactWebExtractionEnabled) {
                status =
                    determineStatusWithoutWebExtractionFeature(
                        projectContextResult,
                    );
                candidates = getCombinedCandidates(projectContextResult);
            } else {
                status = determineStatusWithWebExtractionFeature(
                    projectContextResult,
                    webSearchResult,
                );
                candidates = getCombinedCandidates(
                    projectContextResult,
                    webSearchResult,
                );
            }

            this.setContactExtractionCandidates(candidates.contacts);
            this.setCompanyExtractionCandidates(candidates.companies);

            if (
                status === ContactExtractionResultStatus.Success &&
                candidates.contacts.length > 0
            ) {
                this.onSelectContactCandidate(candidates.contacts[0], true);
            }

            this.setContactExtractionResultStatus(status);
        };

    addCandidatesToDeal: IContactExtractionFeature['addCandidatesToDeal'] = (
        dealId,
    ) => {
        const contactCandidates = this.getSelectedContactCandidates();
        const companyCandidates = this.getSelectedCompanyCandidates();

        this.dealContactsFeature.createContactFromCandidates(
            contactCandidates,
            companyCandidates,
            dealId,
        );
    };

    clear: IContactExtractionFeature['clear'] = () => {
        this.contactExtractionStore.clear();
    };
}
