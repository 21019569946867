import {
    type ContactExtractionStatus,
    type GetContactExtractionResultQuery,
    ExtractionType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export interface DealContactCandidate {
    firstName: string;
    lastName: string;
    companyName: string;
    legitimateInterestText: string;
    sourceUrls: string[];
    role: string;
    extractionType: ExtractionType;
}

export interface DealCompanyCandidate {
    name: string;
    legitimateInterestText: string;
    sourceUrls: string[];
    role: string;
    extractionType: ExtractionType;
}

export interface DealContactCandidateForm {
    firstName: string;
    lastName: string;
    companyName: string;
    notes: string;
    extractionType: ExtractionType;
    customValues?: CustomValue[];
}

export interface DealCompanyCandidateForm {
    name: string;
    notes: string;
    extractionType: ExtractionType;
    customValues?: CustomValue[];
}

export enum ContactExtractionResultStatus {
    Error = 'ERROR',
    NoContacts = 'NO_CONTACTS',
    Success = 'SUCCESS',
    InProgress = 'IN_PROGRESS',
    ArticlesSearchInProgress = 'ARTICLES_SEARCH_IN_PROGRESS',
    WebSearchInProgress = 'WEB_SEARCH_IN_PROGRESS',
    NeverRun = 'NEVER_RUN',
    Disabled = 'DISABLED',
}

export type ContactExtractionResultData = {
    status: ContactExtractionStatus;
    extractionType: ExtractionType;
    extractedContacts: DealContactCandidate[];
    extractedCompanies: DealCompanyCandidate[];
};

export interface ContactExtractionResult {
    projectContextResult: ContactExtractionResultData | null;
    webSearchResult: ContactExtractionResultData | null;
}

type CustomValue = { name: string; value: string };

export const mapContactExtractionResult = (
    result: GetContactExtractionResultQuery['getContactExtractionResult'],
): ContactExtractionResult => {
    const { projectContextResult, webSearchResult } = result;

    const mappedProjectContextResult = projectContextResult
        ? {
              status: projectContextResult.status,
              extractionType: projectContextResult.extractionType,
              extractedContacts: projectContextResult.extractedContacts.map(
                  (contact) => ({
                      ...contact,
                      extractionType: projectContextResult.extractionType,
                  }),
              ),
              extractedCompanies: projectContextResult.extractedCompanies.map(
                  (company) => ({
                      ...company,
                      extractionType: projectContextResult.extractionType,
                  }),
              ),
          }
        : null;

    const mappedWebSearchResult = webSearchResult
        ? {
              status: webSearchResult.status,
              extractionType: webSearchResult.extractionType,
              extractedContacts: webSearchResult.extractedContacts.map(
                  (contact) => ({
                      ...contact,
                      extractionType: webSearchResult.extractionType,
                  }),
              ),
              extractedCompanies: webSearchResult.extractedCompanies.map(
                  (company) => ({
                      ...company,
                      extractionType: webSearchResult.extractionType,
                  }),
              ),
          }
        : null;

    return {
        projectContextResult: mappedProjectContextResult,
        webSearchResult: mappedWebSearchResult,
    };
};
