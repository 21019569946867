export enum MixpanelEventName {
    SetReminder = 'Set reminder',
    ExportedToCrm = 'Exported to CRM',
    SubmittedComment = 'Submitted comment',
    RejectedProject = 'Rejected project',
    SearchedProjectLookup = 'Searched Project Lookup',
    XRayResponseFeedback = 'Voted XRAY response helpful',
    XRayRequestAnswer = 'Requested XRAY question',
    LaunchedQuickAction = 'Launched Quick Action',
    Login = 'Login',
    Logout = 'Logout',
    ConfirmedStageObjective = 'Confirmed a Stage Objective',
    FailedStageObjective = 'Failed a Stage Objective',
    PausedDeal = 'Paused deal',
    ReactivatedDeal = 'Reactivated deal',
    ClickedToOpenDeal = 'Clicked to open deal',
    CreatedCompany = 'Created company',
    CreatedContact = 'Created contact',
    ReferenceDetailsOpened = 'Clicked to open Reference Details',
    ClickedToViewBidDetails = 'Clicked to view bid details',
    ClickedToOpenBid = 'Clicked to open bid',
    ClickedToViewNewsFeedPanel = 'Clicked to view newsfeed panel',
    ClickedToExpandArticleImage = 'Clicked to expand article image',
    ClickedToOpenArticleSource = 'Clicked to open article source',
    ClickedToOpenArticleGoogleSource = 'Clicked to Google source',
    EditedContactParameter = 'Edited contact parameter',
    EditedCompanyParameter = 'Edited company parameter',
    ArchivedContact = 'Archived contact',
    ArchivedCompany = 'Archived company',
    UnarchivedContact = 'Unarchived contact',
    UnarchivedCompany = 'Unarchived company',
    ExpandedContactSection = 'Expanded contact section',
    ExpandedCompanySection = 'Expanded company section',
    AssignedContactDealRole = 'Assigned contact deal role',
    AssignedCompanyDealRole = 'Assigned company deal role',
    InteractDataProvider = 'Interacted with Data Provider Quicklink',
    AchievementsOptedIn = 'Achievements opted in',
    AchievementsOptedOut = 'Achievements opted out',
    AchievementAchieved = 'Achievement badge achieved',
    AchievementReset = 'Badge reset',
    TeamAchievementsModalOpen = 'Achievements open team overview',
    PipelineStatisticsMyDealToggle = 'Dashboard Stats toggled to my deals only',
    PipelineStatisticsSwitchedPipeline = 'Dashboard Stats switched pipeline',
    EditedVpoPromptSettings = 'Edited VPO prompt settings',
    GoogleEnrichmentOutcome = 'Google Enrichment outcome',
    ChangedGoogleEnrichmentMatch = 'Changed Google Enrichment match',
    LoggedOutreachAttempt = 'Logged outreach attempt',
    DeletedOutreachAttempt = 'Deleted logged outreach attempt',
    EditedOutreachAttempt = 'Edited logged outreach attempt',
    OutreachModalOpened = 'Clicked to open Outreach Modal',
    OutreachModalTabSwitched = 'Switched Outreach Modal tab',
    SearchedWithOmniSearch = 'Searched with Omni Search',
    LaunchedNeuralSearch = 'Launched Neural Search',
    SearchedWithNeuralSearch = 'Searched with Neural Search',
    ReportedEnrichmentData = 'Reported enrichment data',
    LaunchedByop = 'Launched BYOP',
    ByopRequestedImport = 'BYOP: Requested Import',
    ClickToUseContactCardLink = 'Clicked to use Card link',
    ClickedToSortDealTable = 'Clicked to sort Deal Table column',
    AppliedDynamicFilter = 'Applied Dynamic Filter',
    ClearedDynamicFilter = 'Cleared Dynamic Filter',
    SearchedContactFinder = 'Searched Contact Finder',
    CreatedContactFromContactFinder = 'Created contact from Contact Finder',
    AttachedDealResource = 'Attached resource to deal',
    OpenedDealResource = 'Opened resource from deal',
    DeletedDealResource = 'Deleted resource from deal',
}

export enum MixpanelEventLocation {
    SegmentControl = 'Segment Control',
    HelperFlyout = 'Helper Flyout Stage Objective Panel',
    KebabMenu = 'Kebab Menu',
    MoveToNextStageModal = 'Move to Next Stage Modal',
    StageObjectivesCard = 'Stage Objectives Card',
    ContactCard = 'Contact Card',
    BackgroundTrigger = 'Background Trigger',
    OneClickNextAction = 'One Click Next Action',
}

export interface IMixpanelEventProperties {
    projectId?: string;
    pipelineId: string;
    pipelineName: string;
    dealId: string;
    currentStageId: string;
    currentStageName: string;
    currentState: string;
}
