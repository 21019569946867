import { makeAutoObservable } from 'mobx';

import { DealsApi } from 'src/data/api/deal/deal.api';
import {
    FilterOpQl,
    SortingOpQl,
    DealAnnualSalesPeriodFragment,
    DealQuarterlySalesPeriodFragment,
    SalesPeriodFrequency,
    DealCreationSource,
    UserAchievementName,
    CategoryNames,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    DealsStore,
    DealsTablePaginationParameters,
} from 'src/data/stores/deals/deals.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import {
    Deal,
    DealOpenSource,
    MinimalDeal,
} from 'src/domain/models/deal/deal.model';
import { history } from 'src/history.utils';
import { createDealPageLink } from 'src/presentation/modules/router/utils/route.utils';
import { doNothing } from 'src/utils/function.utils';
import { Cancellable, handleRequest } from 'src/utils/handle-request.utils';

import {
    customSortDealsByCsvExportedDate,
    FilterFn,
    getExportedCsvDateSortingTokenIfApplied,
    SalesPeriodFilteringOptionsMap,
    SortFn,
} from './deals-list.feature.util';
import { createDealSuccessMessage } from 'src/app-features/project-leads/domain/lead-actions/lead-actions.utils';
import { getI18n, TFunction } from 'react-i18next';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { NeuralSearchCategory } from 'src/app-features/neural-search/data/model';

interface ProjectDataCustomText {
    body: string;
    title?: string;
    publishedDate?: string;
    sourceUrl?: string;
}

interface ProjectDataNeuralSearch {
    articleId: string;
    city: string;
    state: string;
    country: string;
    projectTypes: NeuralSearchCategory[];
    publishedDate: Date;
    summary: string;
    sourceUrls: string[];
    searchId: string;
}
export interface CreateDealPayload {
    pipelineId: string;
    dealTitle: string;
    customTextData?: ProjectDataCustomText;
    neuralSearchData?: ProjectDataNeuralSearch;
    openDeal?: boolean;
}

export interface DealsListFeature {
    minimalDealList: MinimalDeal[];
    isDealsListLoading: boolean;
    needToRequestDealsList: boolean;
    filterOptions: FilterOpQl[];
    sortOptions: SortingOpQl[];
    dealTableRowsToShow: number;
    dealsTablePaginationParams: DealsTablePaginationParameters;
    /**
     * Computed
     * This holds a map of distinct sales periods which are
     * being held by each deal in the minimalDealList
     */
    salesPeriodFilteringOptionsMap: SalesPeriodFilteringOptionsMap;
    minimalDealsMap: Map<string, MinimalDeal>;
    requestDeals: () => Cancellable;
    createDealWithoutProject: (payload: CreateDealPayload) => void;
    setNeedToRequestDealsList: (flag: boolean) => void;
    setFilterOption: FilterFn;
    setSortOption: SortFn;
    setPaginationOption: (pageSize: number, offset?: number) => void;
    setDealTableRowsToShow: (rowsQuantity: number) => void;
    clearOptions: () => void;
}
export class DealsListFeatureImpl implements DealsListFeature {
    t: TFunction<'translation', undefined> = getI18n().t;
    dealTableRowsToShow = 100;

    get minimalDealList() {
        const csvExportedSortingOption =
            getExportedCsvDateSortingTokenIfApplied(this.dealsStore);
        if (csvExportedSortingOption) {
            return customSortDealsByCsvExportedDate(
                this.dealsStore.minimalDealsList,
                csvExportedSortingOption,
            );
        }
        return this.dealsStore.minimalDealsList;
    }

    get minimalDealsMap() {
        return this.dealsStore.minimalDealsMap;
    }

    get isDealsListLoading() {
        return this.dealsStore.isMinimalDealsListLoading;
    }

    get needToRequestDealsList() {
        return this.dealsStore.needToRequestDealsList;
    }

    get filterOptions() {
        return this.dealsStore.filterOptions;
    }

    get setFilterOption() {
        return this.dealsStore.setFilterOption;
    }

    get sortOptions() {
        return this.dealsStore.sortOptions;
    }

    get paginationOptions() {
        return this.dealsStore.paginationOptions;
    }

    get dealsTablePaginationParams() {
        return this.dealsStore.dealsTablePaginationParams;
    }

    get salesPeriodFilteringOptionsMap() {
        return this.minimalDealList.reduce((map, currentDeal) => {
            if (!currentDeal.expectedSalesPeriod) {
                return map;
            }

            if (!map[currentDeal.expectedSalesPeriod]) {
                map[currentDeal.expectedSalesPeriod] =
                    this.convertFormattedSalesPeriodToObject(
                        currentDeal.expectedSalesPeriod,
                    );
            }

            return map;
        }, {} as SalesPeriodFilteringOptionsMap);
    }

    get setSortOption() {
        return this.dealsStore.setSortOption;
    }

    get clearOptions() {
        return this.dealsStore.clearOptions;
    }

    get setPaginationOption() {
        return this.dealsStore.setPaginationOption;
    }

    constructor(
        private dealsApi: DealsApi,
        private pipelineStore: PipelineStore,
        private dealsStore: DealsStore,
        private toasterStore: ToasterStore,
        private achievementService: AchievementService,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    setDealTableRowsToShow = (rowsQuantity: number) => {
        this.dealTableRowsToShow = rowsQuantity;
    };

    setNeedToRequestDealsList = (flag: boolean) => {
        this.dealsStore.setNeedToRequestDealsList(flag);
    };

    requestDeals = (): Cancellable => {
        this.dealsStore.resetMinimalDeals();
        return handleRequest(
            this.dealsApi.getMinimalDeals,
            {
                options: {
                    filtering: this.filterOptions,
                    sorting: this.sortOptions,
                    pagination: this.paginationOptions,
                },
            },
            this.dealsStore.setMinimalDeals,
            this.dealsStore.setLoadingDeals,
            (error) => this.baseStore.onRequestFailed('request-deals', error),
        );
    };

    onDealCreated = (
        deal: Deal | undefined,
        pipelineId: string,
        openDeal?: boolean,
    ) => {
        if (deal) {
            this.dealsStore.dealsMap.set(deal.id, deal);
            this.pipelineStore.setNeedToRequestPipelines(true);

            this.achievementService.call(
                UserAchievementName.CreateYourFirstDeal,
            );

            if (!openDeal) {
                const message = createDealSuccessMessage(
                    deal.id,
                    pipelineId,
                    this.t,
                );
                this.toasterStore.showMessage(message);
            } else {
                history.push(
                    createDealPageLink(
                        deal.id,
                        DealOpenSource.CreatedDealToaster,
                    ),
                );
            }
        }
    };

    createDealWithoutProject = (payload: CreateDealPayload) => {
        const { pipelineId, dealTitle, neuralSearchData } = payload;

        const searchData:
            | Omit<ProjectDataNeuralSearch, 'searchId'>
            | undefined = neuralSearchData
            ? {
                  articleId: neuralSearchData?.articleId,
                  city: neuralSearchData?.city,
                  state: neuralSearchData?.state,
                  country: neuralSearchData?.country,
                  projectTypes: neuralSearchData?.projectTypes,
                  publishedDate: neuralSearchData?.publishedDate,
                  summary: neuralSearchData?.summary,
                  sourceUrls: neuralSearchData?.sourceUrls,
              }
            : undefined;

        const pipeline = this.pipelineStore.getPipeline(pipelineId);
        if (!pipeline) {
            return;
        }

        handleRequest(
            this.dealsApi.createNewDeal,
            {
                pipelineId: pipeline.id,
                title: dealTitle,
                creationSource: neuralSearchData
                    ? DealCreationSource.NeuralSearch
                    : payload.customTextData
                      ? DealCreationSource.ByopPastedContext
                      : DealCreationSource.Manual,
                projectDataFromCustomText: payload.customTextData,
                projectDataFromSearchResult: searchData
                    ? {
                          ...searchData,
                          projectTypes: searchData.projectTypes.map(
                              (a) =>
                                  a.toUpperCase() as unknown as CategoryNames,
                          ),
                      }
                    : undefined,
            },
            (deal?: Deal) =>
                this.onDealCreated(deal, pipeline.id, payload.openDeal),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'create-deal-without-project',
                    error,
                ),
        );
    };

    /**
     * Converts a string in the format "Q1-2022" into a quarterly sales period fragment, or
     * a string in the format 2022 into a annual sales period fragment. This conversion is used
     * when to send a structured object to our BE in order to apply a sales period filter in the
     * deal list.
     */
    convertFormattedSalesPeriodToObject = (
        formattedPeriod: string,
    ): DealAnnualSalesPeriodFragment | DealQuarterlySalesPeriodFragment => {
        if (formattedPeriod.includes('Q')) {
            const quarterlySplit = formattedPeriod.split('-');

            return {
                year: Number(quarterlySplit[1]),
                quarter: Number(quarterlySplit[0].replace('Q', '')),
                frequency: SalesPeriodFrequency.Quarterly,
            };
        }

        return {
            year: Number(formattedPeriod),
            frequency: SalesPeriodFrequency.Annually,
        };
    };
}
