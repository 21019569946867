/**
 * Enum with all available feature flags.
 */
export enum FeatureFlag {
    placeholderFlag = 'placeholderFlag',
    neuralSearch = 'neural-search-fe',
    contactFinder = 'contact-finder',
    newContactForm = 'new-contact-form',
    dealAttachment = 'deal-attachment',
    companyVpo = 'company-vpo',
    contactWebExtraction = 'contact-web-extraction'
}

/**
 * Type for a feature flag value.
 */
export type FeatureFlagValue =
    | string
    | number
    | boolean
    | {
          [key: string]: string | number | boolean;
      };

export interface NonAnonymousUserContext {
    isAnonymous: false;
    user_id: string;
    email: string;
    customData: {
        company_bid: number;
    };
}
